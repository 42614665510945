import { graphql } from "gatsby"
import _ from "lodash"
import React from "react"
import { Helmet } from "react-helmet"

import { CaseStudyCard } from "../components/Card/types"
import EmptyPage from "../components/EmptyPage"
import ItemRows from "../components/ItemRows"
import { PageContentsWrapper, PageLayout } from "../components/PageLayout"
import SectionTitle from "../components/SectionTitle"
import Spacer from "../components/Spacer"
import {
  createStaticContent,
  makeStaticPageTitle,
  transformToArray,
  transformToMap,
} from "../data/transform"

export const query = graphql`
  query($sport: String!, $recordId: String!) {
    locations: allAirtable(filter: { table: { eq: "Locations" } }) {
      ...LocationsFragment
    }
    sport: airtable(table: { eq: "Sports" }, data: { sport: { eq: $sport } }) {
      data {
        sport
        name
        title
      }
    }
    relatedCases: allAirtable(
      filter: {
        table: { eq: "CaseStudies" }
        data: { sportID: { eq: $recordId }, Disable: { ne: true } }
      }
    ) {
      edges {
        node {
          data {
            case
            name
            productID
            clientID
            sportID
            cardImage {
              url
            }
            weight
          }
        }
      }
    }
    sports: allAirtable(filter: { table: { eq: "Sports" } }) {
      ...SportsFragment
    }
    clients: allAirtable(filter: { table: { eq: "Clients" } }) {
      ...ClientsFragment
    }
    products: allAirtable(filter: { table: { eq: "Products" } }) {
      ...ProductsFragment
    }
    caseStudies: allAirtable(
      filter: { table: { eq: "CaseStudies" }, data: { Disable: { ne: true } } }
    ) {
      ...CaseStudiesFragment
    }
    static_content_all: allAirtable(
      filter: { table: { eq: "StaticContent" }, data: { page: { eq: "all" } } }
    ) {
      ...StaticContentFragment
    }
    static_content_sport: allAirtable(
      filter: {
        table: { eq: "StaticContent" }
        data: { page: { eq: "sport" } }
      }
    ) {
      ...StaticContentFragment
    }
  }
`

export default props => {
  const locations = transformToArray(
    props.data,
    "locations",
    record => record.inFooter
  )
  const cases = transformToArray(props.data, "relatedCases")
  const sport = _.get(props, "data.sport", {})

  const sport_name = _.get(sport, "data.title", "")

  const sportMap = transformToMap(props.data, "sports")
  const clientMap = transformToMap(props.data, "clients")
  const productMap = transformToMap(props.data, "products")
  const content = createStaticContent(props.data, "sport")

  return (
    <PageLayout locations={locations} static_content={content}>
      <Helmet>
        <title>
          {makeStaticPageTitle(content, `Our ${sport_name} projects`)}
        </title>
      </Helmet>
      <PageContentsWrapper>
        <SectionTitle title={sport_name} bg_icon="lineart1" />
        <Spacer height="20" />
        {!_.isEmpty(cases) ? (
          <ItemRows child_limit={0}>
            {_.map(cases, (caseStudy, i) => (
              <CaseStudyCard
                key={i}
                caseStudy={caseStudy}
                clientMap={clientMap}
                productMap={productMap}
                sportMap={sportMap}
              />
            ))}
          </ItemRows>
        ) : (
          <EmptyPage
            title={
              `We're hard at work documenting more of our success stories. ` +
              `Check back soon to see all the exciting things we've been doing with ${sport_name}.`
            }
            description=""
          />
        )}

        {/* <Spacer height="20" />

        <SubTitle title="Similar Projects" />
        <ItemRows child_limit={0}>
          {_.map(similar_cases, (caseStudy, i) => (
            <CaseStudyCard
              key={i}
              caseStudy={caseStudy}
              clientMap={clientMap}
              productMap={productMap}
              sportMap={sportMap}
            />
          ))}
        </ItemRows> */}
      </PageContentsWrapper>
    </PageLayout>
  )
}
